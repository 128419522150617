<template>
  <div class="Tests">
    <div class="ProjectList noselect">
      <span class="Title">
        Список ваших тестов
      </span>

      <router-link
        to="/admin/test/add"
        style="position: relative; margin: 40px auto;"
      >
        <GradientButton ButtonText="Добавить" />
      </router-link>

      <div
        v-for="test in TestsList"
        :key="test"
        :class="{
          active: selectedProjectForEdit == test.id,
        }"
      >
        <span>{{ test.name }}</span>
        <span>Описание: {{ test.description }} </span>
        <span
          >Направление:
          {{
            test.competence_id == null
              ? ""
              : findCompetenceName(test.competence_id)
          }}
        </span>

        <router-link :to="`/admin/test/${test.id}/edit`">
          <GradientButton ButtonText="Редактирование" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getSections } from "@/api/competence.js";
import { mapGetters, mapMutations } from "vuex";
import { getUserQuizes, getQuizShortInfo } from "@/api/user_tests.js";

import GradientButton from "@/components/CustomElements/ButtonGradient.vue";

export default {
  components: { GradientButton },
  data() {
    return {
      SectionsList: [],
      TestsList: [],
    };
  },
  async created() {
    //заполнение блока компетенций направлениями, уровнями пользователя и необходимыми уровнями для проекта
    getSections().then((response) => {
      let sections = response.data;
      let competence = [];

      let self = this;

      let tags = new Set(sections.map((section) => section.tag));
      tags.forEach((tag) => {
        let filteredSections = sections
          .filter((section) => section.tag === tag)
          .map((section) => {
            return {
              name: section.name,
              id: section.id,
              levels: section.degrees
                .flatMap((degree) => degree.levels.reverse())
                .reverse()
                .map((x) => x.id),
            };
          });
        competence.push({
          name: tag,
          counter: filteredSections.length,
          CBlock: filteredSections,
        });
      });

      if (competence.length >= 1) {
        self.SectionsList = competence[0].CBlock;
      }
    });

    getUserQuizes(this.user.id).then((response) => {
      this.TestsList = response.data;
    });
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
  },
  methods: {
    ...mapMutations("auth", ["SET_USER"]),

    findCompetenceName(c_id) {
      let comp = this.SectionsList.find((x) => x.id == c_id);
      if (!comp) {
        return "Компетенция не найдена";
      }

      return comp.name;
    },
  },
};
</script>

<style scoped>
.EditModal {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}

.Actions {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
}
.JobContainer {
  position: relative;

  cursor: pointer;

  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;

  background-color: black;
  border-radius: 15px;
}
.JobContainer > .title {
  font-size: 22px;
}
.JobContainer > * {
  text-align: start;
}

.Tests {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 30px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;

  color: white;
}

.ProjectList {
  position: relative;

  width: 50%;

  padding: 12px;
  margin: 0% auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  height: 90vh;
  overflow-y: auto;

  flex-shrink: 1;
}

.ProjectList > div {
  position: relative;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 24px;

  border-radius: 15px;

  cursor: pointer;

  background-color: black;
}
.ProjectList > div > span {
  text-align: left;
}
.ProjectList > div > span:nth-child(1) {
  font-size: 22px;
}

.ProjectList > div.active {
  position: relative;

  color: white;

  background: rgb(43, 53, 88);
  background: linear-gradient(
    128deg,
    rgba(43, 53, 88, 1) 0%,
    rgba(164, 79, 139, 1) 54%,
    rgba(168, 188, 255, 1) 100%
  );
}
.active {
  position: relative;

  color: white;

  background: rgb(43, 53, 88);
  background: linear-gradient(
    128deg,
    rgba(43, 53, 88, 1) 0%,
    rgba(164, 79, 139, 1) 54%,
    rgba(168, 188, 255, 1) 100%
  );
}

.JobsList {
  width: 50%;
  height: 90vh;
  overflow-y: auto;

  flex-shrink: 1;
}

.JobsList,
.JobsList > .Body {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: left;

  padding: 12px;

  height: 90vh;
  overflow-y: auto;

  gap: 20px;
}
.JobsList > .Head {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 40px;
}

.Title {
  position: relative;

  font-size: 24px;
  font-weight: 600;

  flex-shrink: 0;
}
</style>
